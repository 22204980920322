import React, { Component } from "react";

import DelayLink from "../../helpers/delayLink";
import Ink from "react-ink";
import Meta from "../../helpers/meta";
import { NavLink } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { getSettings } from "../../../services/settings/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import PopularPlaces from "../Location/PopularPlaces";
import {getPopularLocations} from "../../../services/popularLocations/actions";

class FirstScreen extends Component {

	static contextTypes = {
		router: () => null
	};

	componentDidMount() {
		//if one config is missing then call the api to fetch settings
		this.props.getPopularLocations();

		// if (!localStorage.getItem("storeColor")) {
		// 	this.props.getSettings();
		// }
		//
		// if (localStorage.getItem("storeColor")) {
		// 	setTimeout(() => {
		// 		this.removeSplashScreen();
		// 	}, 1000);
		// }
	}

	// removeSplashScreen = () => {
	// 	if (document.getElementById("firstScreenSplash")) {
	// 		document.getElementById("firstScreenSplash").remove();
	// 	}
	// 	if (document.getElementById("firstScreenMain")) {
	// 		document.getElementById("firstScreenMain").classList.remove("hidden");
	// 	}
	// };

	handleOnChange = event => {
		// console.log(event.target.value);
		this.props.getSingleLanguageData(event.target.value);
		localStorage.setItem("userPreferedLanguage", event.target.value);
	};

	handleGeoLocationClick = results => {
		// console.log(results);
		const saveGeoLocation = new Promise(resolve => {
			localStorage.setItem("geoLocation", JSON.stringify(results[0]));
			resolve("GeoLocation Saved");
		});
		console.log(results[0]);
		saveGeoLocation.then(() => {
			const userSetAddress = {
				lat: results[0].geometry.location.lat,
				lng: results[0].geometry.location.lng,
				address: results[0].formatted_address,
				house: null,
				tag: null,
			};

			//else save in localstorage for future use (Later when user loggsin or registers, and orders, send this address to db)

			const saveUserSetAddress = new Promise((resolve) => {
				localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
				resolve("Address Saved");
			});
			saveUserSetAddress.then(() => {
				this.context.router.history.push("/");
			});
		});
	};

	render() {
		const { user, popular_locations } = this.props;

		if (localStorage.getItem("userSetAddress") !== null) {
			return <Redirect to="/stores" />;
		}
		return (
			<React.Fragment>
				<Meta
					seotitle={localStorage.getItem("seoMetaTitle")}
					seodescription={localStorage.getItem("seoMetaDescription")}
					ogtype="website"
					ogtitle={localStorage.getItem("seoOgTitle")}
					ogdescription={localStorage.getItem("seoOgDescription")}
					ogurl={window.location.href}
					twittertitle={localStorage.getItem("seoTwitterTitle")}
					twitterdescription={localStorage.getItem("seoTwitterDescription")}
				/>
				<div>
					<div
						className="col-12 p-0 hidden"
						id="firstScreenMain"
						style={{ height: `${window.innerHeight}px` }}
					>
						<div className="block m-0 ">
							<div className="hero-logo">
								<img
									className="logo"
									src="/assets/img/logos/logo-gran.png"
								/>
								<img
									className="hero-img"
									src="/assets/img/timbre_CaC.png"
								/>
							</div>
						</div>
						<div className="m-0">
							<div className="block-content pt-10 text-center">
								<h1 className="welcome-heading mt-10">{localStorage.getItem("firstScreenHeading")}</h1>
								{/*<h2 className="welcome-subheading text-center mt-10 mb-10">*/}
								{/*	{localStorage.getItem("firstScreenSubHeading")}*/}
								{/*</h2>*/}
								<PopularPlaces
									locations={popular_locations}
									handleGeoLocationClick={this.handleGeoLocationClick}
								/>


								{this.props.languages && this.props.languages.length > 1 && (
									<div className="mt-4 d-flex align-items-center justify-content-center">
										<div className="mr-2">{localStorage.getItem("changeLanguageText")}</div>
										<select
											onChange={this.handleOnChange}
											defaultValue={
												localStorage.getItem("userPreferedLanguage")
													? localStorage.getItem("userPreferedLanguage")
													: this.props.languages.filter(lang => lang.is_default === 1)[0].id
											}
											className="form-control language-select"
										>
											{this.props.languages.map(language => (
												<option value={language.id} key={language.id}>
													{language.language_name}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	settings: state.settings.settings,
	user: state.user.user,
	languages: state.languages.languages,
	language: state.languages.language,
	popular_locations: state.popular_locations.popular_locations,
});

export default connect(mapStateToProps, { getSettings, getPopularLocations, getSingleLanguageData })(FirstScreen);
