import { GET_STORE_CATEGORIES } from "./actionTypes";

const initialState = {
    store_categories: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_STORE_CATEGORIES:
            return { ...state, store_categories: action.payload };
        default:
            return state;
    }
}
