import Axios from "axios";
import React, { Component, Fragment } from "react";
import GridList from "react-gridlist"
import { GET_STORE_CATEGORIES_URL } from "../../../../configs";


class StoreCategories extends Component {

    state = {
        loading: true,
        storeCategoriesList: []
    }

    constructor(props) {
        super(props);
    }

    static contextTypes = {
		router: () => null,
	};

    componentDidMount() {
        this.getStoreCategories();
    }

    getStoreCategories = () => {
		const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));

        // make XHR request
        Axios
            .post(GET_STORE_CATEGORIES_URL, {
                latitude: userSetAddress.lat,
                longitude: userSetAddress.lng
            })
            .then((response) => {
                this.setState({
                    loading: false,
                    storeCategoriesList: response.data
                });

                // if (response.data.length === 1) {
                //     this.selectItem(response.data[0]);
                // }
            });
	}

    getGridGap(elementWidth, windowHeight) {
        return 1;
    }

    getColumnCount(elementWidth, gridGap) {
        return 2;
    }

    getWindowMargin(windowHeight) {
        return Math.round(windowHeight * 1.5)
    }

    getItemData(category, columnWidth) {
        return {
            key: category.id
        }
    }

    selectItem(item) {
        sessionStorage.setItem("storeCategorySelected", item.id);
        this.context.router.history.push(`/store-categories/${item.id}`);
    }

    render() {
        return (
            <Fragment>
                { !this.state.loading && (
                    <Fragment>
                        {this.state.storeCategoriesList && this.state.storeCategoriesList.length === 0 ? (
                            <Fragment>
                                <div className="no-restaurants">
                                    <h1 className="restaurant-count">
                                        {localStorage.getItem("noRestaurantMessage")}
                                    </h1>
                                    <img className="cart-empty-img" src="/assets/img/no_restaurants.svg"/>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <GridList items={this.state.storeCategoriesList}
                                    getGridGap={this.getGridGap}
                                    getColumnCount={this.getColumnCount}
                                    getWindowMargin={this.getWindowMargin}
                                    getItemData={this.getItemData}
                                    renderItem={(item) => {
                                        return (
                                            <div key={item.id} className="store-category-item" onClick={() => this.selectItem(item)}>
                                                <img
                                                    src={item.image}
                                                    className="store-category-img"
                                                />
                                                <div className="store-category-label">{item.name}</div>
                                                <div className="store-category-note">{item.total_items} comerços</div>
                                            </div>
                                        )
                                    }}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default StoreCategories;
