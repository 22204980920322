import React, { Component } from "react";
import Axios from "axios";
import { getSettings } from "../../services/settings/actions";
import { connect } from "react-redux";
import { getSingleLanguageData, getAllLanguages } from "../../services/languages/actions";

class CheckVersion extends Component {
	state = {
		updating: false,
	};

	componentDidMount() {
		Axios.get("/version.json").then((response) => {
			//getting new data from version.json file
			const forceNewSettingsServerVersion = response.data.forceNewSettingsVersion;
			const forceCacheClearServerVersion = response.data.forceCacheClearVersion;

			const fetchSettingsAndLanguage = () => {
				return new Promise((resolve) => {
					console.warn("Fetch Settings and Translations");
					this.props.getSettings().then( res => {
						if (localStorage.getItem("userPreferedLanguage") !== null) {
							this.props.getSingleLanguageData(localStorage.getItem("userPreferedLanguage")).then(() => {
								resolve("Completed fetchSettingsAndLanguage");
							});
						} else {
							this.props.getSingleLanguageData(1).then(() => {
								resolve("Completed fetchSettingsAndLanguage");
							});
						}
					});
				});
			};

			const reloadBrowser = () => {
				return new Promise((resolve) => {
					this.setState({ updating: false }, () => {
						window.location.reload(true);
						resolve("Completed reloadBrowser");
					});
				});
			};

			if (!localStorage.getItem("storeColor")) {

				this.setState({ updating: true });
				// Promise.all([clearLocalStorage(), clearCacheStorage(), updateClientVersion(), reloadBrowser()]);
				async function doProcess() {
					fetchSettingsAndLanguage().then( resp => {
						reloadBrowser();
					});
				}

				doProcess();
			} else {
				async function doProcess() {
					fetchSettingsAndLanguage();
				}

				doProcess();
			}

			if (localStorage.getItem("storeColor")) {
				setTimeout(() => {
					this.removeSplashScreen();
				}, 1000);
			}


		});
	}


	removeSplashScreen = () => {
		if (document.getElementById("firstScreenSplash")) {
			document.getElementById("firstScreenSplash").remove();
		}
		if (document.getElementById("firstScreenMain")) {
			document.getElementById("firstScreenMain").classList.remove("hidden");
		}
	};

	render() {
		const { updating } = this.state;
		return (
			<React.Fragment>
				{updating && (
					<React.Fragment>
						<div className="update-full-notification">
							<span className="spin-load" />
						</div>
						<div className="update-full-notification" style={{ zIndex: 9999999999 }}>
							<h1 className="d-flex" style={{ marginTop: "8rem" }}>
								{localStorage.getItem("updatingMessage")}
							</h1>
						</div>
						<div className="col-12 p-0" id="firstScreenSplash">
							<div className="block m-0">
								<div className="block-content p-0">
									<img
										src="/assets/img/splash/splash.jpg"
										className="img-fluid"
										alt={localStorage.getItem("storeName")}
										style={{
											width: "100%"
										}}
									/>
								</div>
							</div>
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

// export default CheckVersion;
const mapStateToProps = (state) => ({
	settings: state.settings.settings,
	languages: state.languages.languages,
	language: state.languages.language,
});

export default connect(
	mapStateToProps,
	{ getSettings, getSingleLanguageData, getAllLanguages }
)(CheckVersion);
