import { GET_STORE_CATEGORIES } from "./actionTypes";
import { GET_STORE_CATEGORIES_URL } from "../../configs";
import Axios from "axios";

export const getStoreCategories = location_name => dispatch => {

    const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));

    Axios.post(GET_STORE_CATEGORIES_URL, {
        latitude: userSetAddress.lat,
        longitude: userSetAddress.lng,
    })
        .then(response => {
            return dispatch({ type: GET_STORE_CATEGORIES, payload: response.data });
        })
        .catch(function(error) {
            console.log(error);
        });
};
